.custom-dropdown-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #FFF;
	padding: 16px;
	border-radius: 4px;
	margin-bottom: 8px;
	border: 1px solid #FFF;

	&:last-of-type {
		margin-bottom: 0px;
	}

	.toggle-arrow {
		transition: transform 0.3s;
		transform: scale(1, 1);
		height: 13px;
	}

	&.card-visible {
		.toggle-arrow {
			transform: scale(-1, -1);
		}
	}

	.container-categoria-bottom-section {
		display: flex;
		flex-direction: column;
		height: 0px;
		overflow: hidden;
		transition: height 0.3s;

		.categoria-item-list-container {
			display: flex;
			flex-direction: column;

			.categoria-item-container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 100%;
				margin-top: 12px;
				padding-top: 12px;
				border-top: 1px solid #E8ECEF;
				word-break: break-word;

				.categoria-item-title {
					font-size: 16px;
					line-height: 24px;
					color: #0C0D0E;
				}
			}
		}
	}

	&.card-visible {
		.container-categoria-top-section {
			.toggle-arrow {
				transform: scale(-1, -1);
			}
		}
	}
}