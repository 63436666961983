.footer {
	position: absolute;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: var(--footer-height);
	padding-left: var(--page-padding);
	padding-right: var(--page-padding);

	.footer-without-max-width-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 100%;
		border-top: 1px solid var(--border-grey);

		.links-and-copyright {
			display: flex;
			flex-direction: column;

			div {
				display: flex;
				flex-direction: row;

				a {
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 0.42px;
					color: var(--primary-color);
					text-transform: uppercase;
					margin-left: 14px;

					&:first-child {
						margin-left: 0px;
					}

					span {
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						letter-spacing: 0.14px;
						color: var(--copyright-text);
						margin-top: 2px;
					}
				}
			}
		}

		.logo {
			position: absolute;
			left: calc(50% - 68.5px);
		}

	}

	@media (max-width: 975px) {
		.footer-without-max-width-container {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;

			.links-and-copyright {
				text-align: center;

				div {
					flex-direction: column;
					margin-top: 15.5px;

					a {
						margin-left: 0px;
						margin-top: 14px;
	
						&:first-child {
							margin-top: 0px;
						}
					}
				}

				span {
					margin-top: 14px;
				}
			}

			.logo {
				position: unset;
			}
		}
	}
}