:root {
	--primary-color: #4b81fa;
	--secondary-color: #3462C8;
	--tertiary-color: #17D7FB;
	--light-blue: #58E3FD;
	--dark-blue: #294789;
	--background-color: #F7F7F7;
	--background-white: #ffffff;
	--background-blue: #E8F0FE;

	--grey-blue: #82ADF8;

	--border-grey: #E7E7E7;

	--line-grey: #E7E7E7;

	--background-grey: #F3F7FE;

	--primary-color-inactive: #95AAD9;
	
	--link-active: #ffffff;
	--link-inactive: #BED2FF;
	--copyright-text: #A0A0A0;

	--table-grey: #F8F8F8;

	--white: #ffffff;

	--black: #000000;

	--button-secondary-hover-active: #ffffff;

	--text-black: #525252;
	--text-black-secondary: #323232;
	--text-black-tertiary: #3D3D3D;

	--balloon-grey: #CDDBFA;

	--input-border: rgba(0, 0, 0, 0.23);
}
