.layout-container {
	display: flex;
	flex-direction: column;
	position: relative;
	background: #F7F7F7;

	.page-container {
		display: flex;
		flex-direction: column;
		padding-left: var(--page-padding);
		padding-right: var(--page-padding);
		padding-bottom: calc(var(--page-padding) + var(--footer-height));
		min-height: calc(100vh - var(--header-height));
		width: 100%;

		.page-container-without-max-width-container {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;

			.layout-hr {
				height: 1px;
				margin-top: unset;
				margin-bottom: 33px;
				width: 100%;
				border-top: 1px solid var(--line-grey);
			}
		}
	}

	@media (max-width: 975px) {
		.page-container {
			.page-container-without-max-width-container {
				.layout-hr {
					margin-bottom: 0px;
				}
			}
		}
	}
}