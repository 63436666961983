:root {
	--page-padding: 30px;
	--header-height: 108px;
	--footer-height: 161px;
}

@media (max-width: 975px) {
	:root {
		--page-padding: 16px;
	}
}