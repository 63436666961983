.header {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
	position: sticky;
	top: 0;
	width: 100%;
	height: var(--header-height);
	padding-left: var(--page-padding);
	padding-right: var(--page-padding);
	background: var(--background-color);

	.header-without-max-width-container {
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: space-between;
		align-items: center;

		.menu-logo-and-nav {
			display: flex;
			flex-direction: row;
			align-items: center;

			.menu {
				height: 40px;
				width: 40px;
				cursor: pointer;
			}

			.logo {
				display: flex;
				height: 34px;
				width: 150px;
				margin-left: 20px;

				a {
					height: 100%;
					width: 100%;

					img {
						height: 100%;
						width: 100%;
					}

					.logo-mobile{
						display: none;
					}
				}
			}

			nav {
				display: flex;
				flex-direction: row;
				margin-left: 100px;

				a {
					font-family: "Inter";
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 16.94px;
					letter-spacing: -2%;
					margin-left: 16px;
					color: var(--text-black-tertiary);
					position: relative;
					padding: 12px 10px;
					transition: color 0.3s ease, background-color 0.3s ease;
					border-radius: 30px;

					&:hover,
					&:active,
					&.active {
						color: var(--white) !important;
						background-color: var(--primary-color);
						padding: 12px 10px;
					}

					&:first-child {
						margin-left: 0px;
					}
				}
			}
		}

		.language-notification-user {
			display: flex;
			flex-direction: row;
			align-items: center;

			.vertical-separator {
				width: 1px;
				height: 29.5px;
				background-color: var(--border-grey);
				margin: 0px 23px;
			}

			.round-button {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 40px;
				width: 40px;
				border-radius: 50%;
				margin-left: 10px;
				background-color: var(--tertiary-color);

				img {
					height: 20px;
					width: 16px;
				}
			}

			.user-button-menu {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				margin-right: 7px;
				max-width: 137px;

				.user-button-menu-name {
					color: var(--text-black-tertiary);
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 16.94px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 100%;
				}

				.user-button-menu-email {
					color: var(--text-black-tertiary);
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 14.52px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 100%;
				}
			}

			.user-button-menu-mobile {
				display: none;
			}
		}

	}

	@media (max-width: 975px) {
		.header-without-max-width-container {
			.menu-logo-and-nav {
				.menu {
					width: 50px;
					height: 50px;
				}

				.logo {
					height: 90px;
					width: 90px;
					margin-left: 10px;

					.logo-desktop{
						display: none;
					}
					.logo-mobile{
						display: flex !important;
						margin-left: 10px;
						width: 35px;
					}
				}

				nav {
					display: none;
				}
			}

			.language-notification-user {
				.user-button-menu {
					display: none;
				}

				.vertical-separator {
					display: none;
				}

				.MuiInput-input,
				.MuiSelect-nativeInput {
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					padding: 5px 26px 5px 11px !important;

				}

				.round-button {
					height: 35px;
					width: 35px;
					margin-left: 5px;

					img {
						width: 15px;
						height: 15px;
					}
				}

				.user-button-menu-mobile {
					display: flex;
					border-radius: 50%;
					width: 30px;
					height: 30px;
					overflow: hidden;
					transition: 0.2s;

					img {
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}