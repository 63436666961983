.chat-balloon-container {
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: 109px;
	right: 33px;
	z-index: 4;
	filter: drop-shadow(0px 7px 5px rgba(212, 212, 212, 0.25));

	button {
		background-color: transparent;
		border: none;
		margin: 0;
		padding: 0;
		text-align: inherit;
		font: inherit;
		border-radius: 0;
		appearance: none;
		height: 60px;
		width: 60px;
		z-index: 5;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.chat-message-container {
		display: flex;
		flex-direction: column;
		width: 217px;
		right: 10px;
		top: 12px;
		position: absolute;

		.first-message-box {
			display: flex;
			flex-direction: column;
			padding: 12px 60px 20px 7px;
			background-color: var(--primary-color);
			border-radius: 4px;

			.first-title {
				color: var(--light-blue);
				font-size: 10px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.3px;
			}

			.first-description {
				color: var(--white);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}

		.second-message-box {
			display: flex;
			flex-direction: column;
			padding: 6px 37px 7px 7px;
			background-color: var(--balloon-grey);
			margin-top: -11px;
			border-radius: 4px;
			z-index: 5;

			.first-title {
				color: var(--primary-color);
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
			}

			.first-description {
				color: var(--primary-color);
				font-size: 10px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
		}

		.third-message-box {
			display: flex;
			flex-direction: column;
			padding: 20px 37px 6px 7px;
			background-color: var(--white);
			border-radius: 4px;
			margin-top: -10px;

			.icon-and-text-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-top: 5.76px;

				img {
					width: 16px;
					height: 16px;
				}

				.message-text {
					color: var(--text-black);
					font-size: 10px;
					font-style: normal;
					font-weight: 500;
					line-height: normal;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					max-width: 100%;
					margin-left: 2px;
				}

				&:first-child {
					margin-top: 5.76px;
				}
			}


		}
	}

	&.is-chat-open {
		bottom: 177px;
	}

	@media (max-width: 975px) {
		bottom: 140px;
		right: 3px;

		.chat-message-container {
			display: none;
		}

		&.is-chat-open {
			bottom: 177px;

			.chat-message-container {
				display: flex;
			}
		}
	}
}