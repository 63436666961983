table {
	border: 0px;

	thead {
		border: 0px;
		background-color: var(--background-white);
		text-align: left;

		th {
			font-size: 16px;
			font-weight: 500 !important;
			line-height: normal;
			color: var(--text-black);
			border: 0px;
			vertical-align: middle;
			padding: 14px 16px !important;
			border-bottom: 0px !important;

			&.with-min-fixed-width {
				min-width: 120px;
			}
		}
	}

	tbody {
		text-align: left;

		tr {
			border: 0px;
			border-bottom: 1px solid #F3F4F6;

			td {
				border-top: 0px !important;
				color: var(--text-black);
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				border: 0px;
				line-height: normal;
				vertical-align: middle !important;
				padding: 14px 16px !important;
				border-bottom: 0px !important;
				word-break: break-word;

				&.with-min-fixed-width {
					min-width: 120px;
				}
			}
		}

		&::before {
			content: '';
			display: block;
		}

		&.without-before {
			&::before {
				display: none;
			}
		}
	}

	&.table-striped {
		tbody {
			tr {
				&:nth-of-type(odd) {
					background-color: #fff;
				}

				&:nth-of-type(even) {
					background-color: var(--table-grey);
					border: 0;
				}
			}
		}
	}
}

.p-datatable {
	background: var(--background-white);

	.p-datatable-header {
		margin-bottom: 0;

	}

	.p-datatable-thead {
		tr {
			th {
				border: 0 !important;
				text-align: left !important;
				background-color: unset !important;
				color: var(--text-black)
			}
		}
	}

	.p-datatable-tbody {
		tr {
			td {
				border: 0 !important;
				vertical-align: middle !important;
				color: var(--text-black);

				p {
					margin-bottom: 0px;
				}
			}

			&:nth-child(odd) {
				background-color: #F6F8FC !important;
				border: 1px solid #DBE0EB !important;
			}

			&:nth-child(even) {
				background-color: #FFF !important;
				border: 0 !important;
			}
		}

	}

	@media (max-width: 975px) {
		
		.p-datatable-header {
			padding: 0 !important;

			div {
				display: flex;
				float: none !important;
				justify-content: center;

				.p-inputtext {
					width: 55%;
				}
			}
		}
	}
}