.custom-select-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0px;
	text-decoration: none;
	outline: none;

	.MuiSelect-icon {
		top: calc(50% - 9px);
		right: 11px;
	}

	.MuiInput-input,
	.MuiSelect-nativeInput {
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		padding: 10px 31px 10px 16px !important;
		border-radius: 48px;

		&:hover,
		&:active,
		&:focus {
			border-radius: 48px;
		}
	}

	&.custom-text {
		&.text-primary {
			.MuiInput-input,
			.MuiSelect-nativeInput {
				color: var(--primary-color);

				&:hover,
				&:active,
				&:focus,
				&[aria-expanded=true] {
					color: var(--black);
				}
			}

			&:hover,
			&:active,
			&:focus {
				svg {
					color: var(--black);
				}
			}

			svg {
				color: var(--primary-color);
			}
		}

		&.text-light-blue {
			.MuiInput-input,
			.MuiSelect-nativeInput {
				color: var(--light-blue);

				&:hover,
				&:active,
				&:focus,
				&[aria-expanded=true] {
					color: var(--white);
				}
			}

			&:hover,
			&:active,
			&:focus {
				svg {
					color: var(--white);
				}
			}

			svg {
				color: var(--light-blue);
			}
		}
	}

	&.custom-outlined {
		&.outlined-white {

			.MuiInput-input,
			.MuiSelect-nativeInput {
				color: var(--black);
				border: 2px solid var(--border-grey);

				&:hover,
				&:active,
				&:focus,
				&[aria-expanded=true] {
					transition: box-shadow 0.3s ease;
					box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
				}
			}

			svg {
				color: var(--black);
			}
		}
	}

	@media (max-width: 975px) {
		&.custom-text {
			&.text-primary {
				.MuiInput-input,
				.MuiSelect-nativeInput {
					font-size: 12px;
				}
			}
		}
	}
}