.sidebar-container {
	display: flex;
	opacity: 0;
	flex-direction: column;
	position: fixed;
	left: -323px;
	top: 0px;
	height: 100%;
	width: 323px;
	z-index: -1;
	background-color: var(--white);
	transition: left 0.2s, opacity 0.2s;
	box-shadow: 3px 7px 12px 2px rgba(199, 197, 197, 0.25);
	padding-bottom: var(--page-padding);

	&.sidebar-open {
		z-index: 100;
		opacity: 1;
		left: 0;
	}

	.sidebar-top-section {
		display: flex;
		flex-direction: row;
		align-items: center;
		min-height: var(--header-height);
		height: var(--header-height);
		border-bottom: 1px solid var(--border-grey);
		padding: 0px var(--page-padding) 0px var(--page-padding);

		.sidebar-close-menu {
			height: 65px;
			width: 65px;
			cursor: pointer;
		}

		.sidebar-logo {
			padding-top: 5px;
			width: 168.865px;
			height: 40px;
			margin-left: 10px;
		}
	}

	.sidebar-menu-container {
		display: flex;
		flex-direction: column;
		margin-top: 35px;
		height: 100%;
		max-height: 100%;
		flex-grow: 1;
		overflow-y: hidden;
		position: relative;

		.item-root-ul {
			display: flex;
			flex-direction: column;
			list-style: none;
			margin-top: 0px;
			margin-bottom: 0px;
			padding: 0px;
			padding: 0px var(--page-padding) 0px var(--page-padding);

			.item-root-li {
				margin-top: 5px;

				.sidebar-nav-link {
					display: flex;
					flex-direction: column;
					cursor: pointer;
					width: 100%;
					min-height: 25px;
					align-items: flex-start;

					.sidebar-nav-link-fit-content {
						display: flex;
						flex-direction: column;
						width: fit-content;
						word-break: break-word;
						position: relative;
						padding: 10px;

						.sidebar-nav-link-title {
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
							line-height: 21.78px;
							letter-spacing: 3%;
							color: var(--grey-blue);
							font-family: "Inter";
						}
					}

					&:hover,
					&:active,
					&.active {
						.sidebar-nav-link-fit-content {

							width: 100%;
							background-color: var(--background-blue);
							min-height: 30px;
							display: flex;
							justify-content: center;
							border-radius: 30px;
							padding: 10px;

							.sidebar-nav-link-title {
								color: var(--primary-color) !important;
							}
						}

						.point-decoration {
							width: 6px;
							height: 6px;
							background-color: var(--primary-color);
							border-radius: 50%;
							position: absolute;
							right: 0;
							margin-right: 10px;
						}
					}
				}

				.custom-dropdown-container {
					padding: 10px;
					border: unset;

					.sidebar-custom-dropdown-top {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						cursor: pointer;
						width: 100%;
						word-break: break-word;
						min-height: 25px;

						.sidebar-dropdown-title {
							font-size: 16px;
							font-style: normal;
							font-weight: 600;
							line-height: 21.78px;
							letter-spacing: 3%;
							color: var(--grey-blue);
							font-family: "Inter";
							margin-right: 20px;
						}

						.toggle-arrow {
							color: var(--primary-color);
						}

						&:hover,
						&:active,
						&.active {
							.sidebar-dropdown-title {
								color: var(--primary-color) !important;
							}

							.toggle-arrow {
								color: var(--primary-color);
							}
						}
					}

					.item-submenu-ul {
						display: flex;
						flex-direction: column;
						margin-top: 0px;
						margin-bottom: 0px;
						padding: 0px;
						padding-left: 16px;
						margin-top: 5px;

						.item-submenu-li {
							margin-top: 5px;
							list-style: none;

							.sidebar-nav-link {
								display: flex;
								flex-direction: column;
								cursor: pointer;
								width: 100%;
								min-height: 25px;
								align-items: flex-start;

								.sidebar-nav-link-fit-content {
									display: flex;
									flex-direction: column;
									width: 100%;
									word-break: break-word;

									.sidebar-nav-link-title {
										font-size: 14px;
										font-style: normal;
										font-weight: 600;
										line-height: 21.78px;
										letter-spacing: 3%;
										color: var(--grey-blue);
										font-family: "Inter";
									}
								}

								&:hover,
								&:active,
								&.active {
									.sidebar-nav-link-fit-content {
										.sidebar-nav-link-title {
											color: var(--primary-color) !important;

										}

										.underline-decoration {
											visibility: visible;
										}
									}
								}
							}
						}
					}
				}

				&:first-child {
					margin-top: 0px;
				}
			}
		}

	}

	@media (max-width: 975px) {
		&.sidebar-open {
			width: 100%;

			.sidebar-top-section {
				.sidebar-close-menu {
					cursor: pointer;
				}

				.sidebar-logo {
					margin-left: 2px;
				}
			}
		}
	}
}