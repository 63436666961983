.custom-menu-container {
	color: var(--white);


	button {
		text-transform: none;
	}

	i {
		color:  var(--text-black-tertiary);
		font-size: 10px !important;
	}

	&.open {
		i {
			transform: rotate(180deg);
		}
	}
}